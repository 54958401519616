/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "kaldiという音声処理のOSSを試した"), "\n", React.createElement(_components.p, null, "tedium model(TEDの公演を学習したモデル)を使って、音声認識するまで行う。ソフトウェアはdockerコンテナで起動する"), "\n", React.createElement(_components.h3, {
    id: "モデルテストデータの準備",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%83%A2%E3%83%87%E3%83%AB%E3%83%86%E3%82%B9%E3%83%88%E3%83%87%E3%83%BC%E3%82%BF%E3%81%AE%E6%BA%96%E5%82%99",
    "aria-label": "モデルテストデータの準備 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "モデル、テストデータの準備"), "\n", React.createElement(_components.p, null, "ホストマシンで行う"), "\n", React.createElement(_components.p, null, "モデルのダウンロード"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">mkdir</span> <span class=\"token parameter variable\">-p</span> test/models\n<span class=\"token builtin class-name\">cd</span> test/models\n\n<span class=\"token function\">wget</span> --no-check-certificate https://phon.ioc.ee/~tanela/tedlium_nnet_ms_sp_online.tgz\n\n<span class=\"token function\">tar</span> zxvf tedlium_nnet_ms_sp_online.tgz\n\n<span class=\"token function\">ls</span> -\nenglish  tedlium_nnet_ms_sp_online.tgz</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "テストデータのダウンロード"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">mkdir</span> <span class=\"token parameter variable\">-p</span> test/data\ntest/data\n<span class=\"token function\">wget</span> https://raw.githubusercontent.com/alumae/kaldi-gstreamer-server/master/test/data/bill_gates-TED.mp3\n<span class=\"token function\">wget</span> https://raw.githubusercontent.com/alumae/kaldi-gstreamer-server/master/test/data/bill_gates-TED.txt</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h3, {
    id: "docker-composeの作成",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#docker-compose%E3%81%AE%E4%BD%9C%E6%88%90",
    "aria-label": "docker composeの作成 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "docker-composeの作成"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"yaml\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-yaml line-numbers\"><code class=\"language-yaml\"><span class=\"token key atrule\">version</span><span class=\"token punctuation\">:</span> <span class=\"token string\">'3.3'</span>\n<span class=\"token key atrule\">services</span><span class=\"token punctuation\">:</span>\n  <span class=\"token key atrule\">app</span><span class=\"token punctuation\">:</span>\n    <span class=\"token key atrule\">image</span><span class=\"token punctuation\">:</span> jcsilva/docker<span class=\"token punctuation\">-</span>kaldi<span class=\"token punctuation\">-</span>gstreamer<span class=\"token punctuation\">-</span>server\n    <span class=\"token key atrule\">volumes</span><span class=\"token punctuation\">:</span>\n      <span class=\"token punctuation\">-</span> ./test<span class=\"token punctuation\">:</span>/opt/test\n    <span class=\"token key atrule\">ports</span><span class=\"token punctuation\">:</span>\n      <span class=\"token punctuation\">-</span> 8080<span class=\"token punctuation\">:</span><span class=\"token number\">80</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.h3, {
    id: "起動とテスト",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E8%B5%B7%E5%8B%95%E3%81%A8%E3%83%86%E3%82%B9%E3%83%88",
    "aria-label": "起動とテスト permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "起動とテスト"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/jcsilva/docker-kaldi-gstreamer-server#how-to-use"
  }, "https://github.com/jcsilva/docker-kaldi-gstreamer-server#how-to-use)"), ")\n", React.createElement(_components.a, {
    href: "https://github.com/jcsilva/docker-kaldi-gstreamer-server#testing"
  }, "https://github.com/jcsilva/docker-kaldi-gstreamer-server#testing)"), ")\nを参考にする"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "サーバ起動"), "\n"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token function\">docker-compose</span> run <span class=\"token parameter variable\">--rm</span> <span class=\"token parameter variable\">-p</span> <span class=\"token number\">8080</span>:80 app /bin/bash</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "でコンテナ内に入って"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\"><span class=\"token builtin class-name\">cd</span> /\n<span class=\"token function\">sh</span> start.sh <span class=\"token parameter variable\">-y</span> /opt/kaldi-gstreamer-server/sample_english_nnet2.yaml</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "でサーバの起動\nwebsocketを使うらしい"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "起動確認"), "\n"), "\n", React.createElement(_components.p, null, "http://www.websocket.org/echo.html"), "\n", React.createElement(_components.p, null, "からLocationを"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">ws://localhost:8080/client/ws/status</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "に設定してconnectして"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">RECEIVED: <span class=\"token punctuation\">{</span><span class=\"token string\">\"num_workers_available\"</span><span class=\"token builtin class-name\">:</span> <span class=\"token number\">1</span>, <span class=\"token string\">\"num_requests_processed\"</span><span class=\"token builtin class-name\">:</span> <span class=\"token number\">0</span><span class=\"token punctuation\">}</span></code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "と返ってくれば起動成功"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "テスト"), "\n"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre style=\"counter-reset: linenumber NaN\" class=\"language-bash line-numbers\"><code class=\"language-bash\">python kaldigstserver/client.py <span class=\"token parameter variable\">-u</span> ws://localhost:80/client/ws/speech  <span class=\"token parameter variable\">-r</span> <span class=\"token number\">8192</span> /opt/test/data/bill_gates-TED.mp3</code><span aria-hidden=\"true\" class=\"line-numbers-rows\" style=\"white-space: normal; width: auto; left: 0;\"><span></span></span></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "を実行すると認識結果が流れる"), "\n", React.createElement(_components.h3, {
    id: "references",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#references",
    "aria-label": "references permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "references"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/alumae/kaldi-gstreamer-server"
  }, "https://github.com/alumae/kaldi-gstreamer-server)"), ")"), "\n", React.createElement(_components.li, null, React.createElement(_components.img, {
    src: "https://github.com/jcsilva/docker-kaldi-gstreamer-server",
    alt: ""
  })), "\n", React.createElement(_components.li, null, "http://demura.net/athome/12743.html"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://qiita.com/sayonari/items/936171340990c474be73#%EF%BC%95%EF%BC%92%E9%9F%B3%E5%A3%B0%E8%AA%8D%E8%AD%98%E7%94%A8%E3%83%A2%E3%83%87%E3%83%AB%E3%81%AE%E3%83%80%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%89%E8%8B%B1%E8%AA%9Etedlium"
  }, "https://qiita.com/sayonari/items/936171340990c474be73#%EF%BC%95%EF%BC%92%E9%9F%B3%E5%A3%B0%E8%AA%8D%E8%AD%98%E7%94%A8%E3%83%A2%E3%83%87%E3%83%AB%E3%81%AE%E3%83%80%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%89%E8%8B%B1%E8%AA%9Etedlium)"), ")"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
